/*
 * Author: dizhong zhu
 * Date: 08/08/2024
 */

import http from './http'
import { ScanT } from '../store/scan'
import { QuickSizeT } from '../store/quicksize'

export async function getQuickSizeListRequest() {
    return (await http.get(`/dashboard/quick-size`)).data
}

export async function delQuickSizeRequest(items: QuickSizeT[]) {
    const data: { [key: string]: string[] } = {}

    for (const item of items) {
        if (!data[item.domain]) {
            data[item.domain] = [] // Initialize the array if it doesn't exist
        }
        data[item.domain].push(item.uuid) // Push the domain into the array
    }

    return (await http.delete(`/dashboard/quick-size`, { data })).data
}
