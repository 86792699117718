import { combineReducers } from '@reduxjs/toolkit'
import authReducer from 'store/auth'
import layoutReducer from 'store/layout'
import tenantReducer from 'store/tenant'
import analyticsReducer from 'store/analytics'
import configurationsReducer from 'store/configuration'
import sizeReducer from 'store/sizes'
import scanReducer from 'store/scan'
import quickSizeReducer from 'store/quicksize'
import settingReducer from 'store/settings'

const rootReducer = combineReducers({
    auth: authReducer,
    layout: layoutReducer,
    tenant: tenantReducer,
    analytics: analyticsReducer,
    configuration: configurationsReducer,
    size: sizeReducer,
    scan: scanReducer,
    quickSize: quickSizeReducer,
    settings: settingReducer,
})

export default rootReducer
