import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement, registerables } from 'chart.js'
import { Pie } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, PointElement, LineElement, CategoryScale, ...registerables)

function Chart({ stats }: any) {
    const data = {
        labels: ['Used', 'Non-Used'],
        datasets: [
            {
                label: '# of Widget Usage',
                data: [stats['noofOrders']?.true, stats['noofOrders']?.false],
                backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
                borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
                borderWidth: 1,
            },
        ],
    }

    const headerClass = 'py-2 px-4 border-b border-gray-200 font-semibold uppercase text-gray-600 bg-gray-200'
    const rowClass = 'py-2 px-4 border-b bg-zinc-50 border-gray-200'

    return (
        <div>
            <div className="grid md:flex justify-around gap-4">
                <div className="h-72 mb-20 px-5">
                    <p className="text-lg font-semibold mb-4">Checkouts</p>
                    <Pie data={data} />
                </div>
                <div className="mt-2">
                    <p className="text-lg font-semibold mb-4">Metrics</p>
                    <table className="min-w-full bg-gray-100 border border-gray-200">
                        <thead>
                            <tr>
                                <th className={`${headerClass} w-52`}></th>
                                <th className={`${headerClass} w-40`}>Usage</th>
                                <th className={`${headerClass} w-40`}>Non-usage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={rowClass}>Conversion</td>
                                <td className={rowClass}>{(stats['conversion']?.true * 100).toFixed(2)}%</td>
                                <td className={rowClass}>{(stats['conversion']?.false * 100).toFixed(2)}%</td>
                            </tr>
                            <tr>
                                <td className={rowClass}>AOV (£)</td>
                                <td className={rowClass}>{stats['AOV']?.true.toFixed(2)}</td>
                                <td className={rowClass}>{stats['AOV']?.false.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td className={rowClass}>No. of Checkouts</td>
                                <td className={rowClass}>{stats['noofOrders']?.true}</td>
                                <td className={rowClass}>{stats['noofOrders']?.false}</td>
                            </tr>
                            <tr>
                                <td className={rowClass}>No. of Items Bought</td>
                                <td className={rowClass}>{stats['noofItems']?.true}</td>
                                <td className={rowClass}>{stats['noofItems']?.false}</td>
                            </tr>
                            <tr>
                                <td className={rowClass}>Total Checkout (£)</td>
                                <td className={rowClass}>{stats['checkoutAmount']?.true}</td>
                                <td className={rowClass}>{stats['checkoutAmount']?.false}</td>
                            </tr>
                            <tr>
                                <td className={rowClass}>Returns</td>
                                <td className={rowClass}>{stats['returnOrder']?.true}</td>
                                <td className={rowClass}>{stats['returnOrder']?.false}</td>
                            </tr>
                            <tr>
                                <td className={rowClass}>Return Rate</td>
                                <td className={rowClass}>{(stats['returnRate']?.true * 100)?.toFixed(2)}%</td>
                                <td className={rowClass}>{(stats['returnRate']?.false * 100)?.toFixed(2)}%</td>
                            </tr>
                            <tr>
                                <td className={rowClass}>Refund Amount (£)</td>
                                <td className={rowClass}>{stats['refundAmount']?.true?.toFixed(2)}</td>
                                <td className={rowClass}>{stats['refundAmount']?.false?.toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mt-2">
                    <p className="text-lg font-semibold mb-4">Completions</p>
                    <table className="min-w-full bg-gray-100 border border-gray-200">
                        <thead>
                            <tr>
                                <th className={`${headerClass} w-52`}></th>
                                <th className="py-2 px-4 border-b border-gray-200 font-semibold text-gray-600 bg-gray-200 w-40">Completions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={rowClass}>Quick sizing</td>
                                <td className={rowClass}>{(stats['completion']?.quick_sizing * 100).toFixed(2)}%</td>
                            </tr>
                            <tr>
                                <td className={rowClass}>Full Scan</td>
                                <td className={rowClass}>{(stats['completion']?.full_scan * 100).toFixed(2)}%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mt-2">
                    <p className="text-lg font-semibold mb-4">Appendix</p>
                    <table className="min-w-full bg-gray-100 border border-gray-200">
                        <thead>
                            <tr>
                                <th className={`${headerClass} w-52`}></th>
                                <th className="py-2 px-4 border-b border-gray-200 font-semibold text-gray-600 bg-gray-200 w-40">Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={rowClass}>Click Rate</td>
                                <td className={rowClass}>{(stats['appendix']?.click_rate * 100).toFixed(2)}%</td>
                            </tr>
                            <tr>
                                <td className={rowClass}>Adoption</td>
                                <td className={rowClass}>{(stats['appendix']?.adoption * 100).toFixed(2)}%</td>
                            </tr>
                            <tr>
                                <td className={rowClass}>No. of unique visitors</td>
                                <td className={rowClass}>{stats['appendix']?.total_visit}</td>
                            </tr>
                            <tr>
                                <td className={rowClass}>No. of widget clicks</td>
                                <td className={rowClass}>{stats['appendix']?.widget_open}</td>
                            </tr>
                            <tr>
                                <td className={rowClass}>Quick Sizing Complete</td>
                                <td className={rowClass}>{stats['appendix']?.quick_sizing}</td>
                            </tr>
                            <tr>
                                <td className={rowClass}>Full Scan Complete</td>
                                <td className={rowClass}>{stats['appendix']?.full_scan}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export { Chart }
